import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/auth",
    component: () => import("@/layouts/Auth.vue"),
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("@/views/auth/login.vue"),
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/layouts/Dashboard.vue"),
    children: [
      {
        name: "dashboard",
        path: "",
        component: () => import("@/views/dashboard/index.vue"),
      },
      {
        name: "dashboard-anggaran",
        path: "/dashboard/anggaran",
        component: () => import("@/views/dashboard/budgets.vue"),
      },

      {
        name: "anggaran",
        path: "/anggaran",
        component: () => import("@/views/budgets/index.vue"),
      },
      {
        name: "detail-budgets",
        path: "/anggaran/:id",
        component: () => import("@/views/budgets/detail.vue"),
      },

      {
        name: "bidang-perencanaan",
        path: "/bidang-perencanaan",
        component: () => import("@/views/plans/index.vue"),
      },
      {
        name: "pekerjaan-bidang-perencanaan",
        path: "/bidang-perencanaan/pekerjaan/:id",
        component: () => import("@/views/plans/work/index.vue"),
      },
      {
        name: "create-pekerjaan-bidang-perencanaan",
        path: "/bidang-perencanaan/pekerjaan/:id/create",
        component: () => import("@/views/plans/work/create.vue"),
      },
      {
        name: "edit-pekerjaan-bidang-perencanaan",
        path: "/bidang-perencanaan/pekerjaan/:package_id/edit/:id",
        component: () => import("@/views/plans/work/edit.vue"),
      },
      // {
      //   name: "approval-pekerjaan-bidang-perencanaan",
      //   path: "/bidang-perencanaan/pekerjaan/:id/approval/:id",
      //   component: () => import("@/views/plans/work/approval.vue"),
      // },

      {
        name: "pekerjaan-bidang-perencanaan-pra",
        path: "/bidang-perencanaan-pra/pekerjaan/ :id",
        component: () => import("@/views/plans/work/index-pra.vue"),
      },

      /** Construction V2 */

      {
        name: "bidang-pembangunan",
        path: "/bidang-pembangunan-v2",
        component: () => import("@/views/construction_v2/index.vue"),
      },
      {
        name: "pekerjaan-bidang-pembangunan",
        path: "/bidang-pembangunan-v2/pekerjaan/:project_package_id/:sub_activity_id",
        component: () => import("@/views/construction_v2/work/index.vue"),
      },
      {
        name: "create-pekerjaan-bidang-pembangunan",
        path: "/bidang-pembangunan-v2/pekerjaan/:id/create",
        component: () => import("@/views/construction_v2/work/create.vue"),
      },
      {
        name: "edit-pekerjaan-bidang-pembangunan",
        path: "/bidang-pembangunan-v2-pekerjaan/edit/:id",
        component: () => import("@/views/construction_v2/work/edit.vue"),
      },
      // {
      //   name: "approval-pekerjaan-bidang-pembangunan",
      //   path: "/bidang-pembangunan-v2/pekerjaan/approval/:id",
      //   component: () => import("@/views/construction_v2/work/approval.vue"),
      // },

      {
        name: "pekerjaan-bidang-pembangunan-pra",
        path: "/bidang-pembangunan-pra/pekerjaan/:id",
        component: () => import("@/views/construction_v2/work/index-pra.vue"),
      },

      /** End Construction V2 */

      {
        name: "index-constructions",
        path: "/bidang-pembangunan",
        component: () => import("@/views/construction/index.vue"),
      },
      {
        name: "create-constructions",
        path: "/bidang-pembangunan/create",
        component: () => import("@/views/construction/create.vue"),
      },
      {
        name: "edit-constructions",
        path: "/bidang-pembangunan/edit/:id",
        component: () => import("@/views/construction/edit.vue"),
      },
      // {
      //   name: "approval-constructions",
      //   path: "/bidang-pembangunan/approval/:id",
      //   component: () => import("@/views/construction/approval.vue"),
      // },

      {
        name: "peta-sebaran",
        path: "/peta-sebaran/:step",
        component: () => import("@/views/project/Maps.vue"),
      },
      {
        name: "bidang-pengawasan",
        path: "/bidang-pengawasan",
        component: () => import("@/views/supervision/index.vue"),
      },
      {
        name: "create-supervision",
        path: "/bidang-pengawasan/create",
        component: () => import("@/views/supervision/create.vue"),
      },
      {
        name: "edit-supervision",
        path: "/bidang-pengawasan/edit/:id",
        component: () => import("@/views/supervision/edit.vue"),
      },
      // {
      //   name: "approval-supervision",
      //   path: "/bidang-pengawasan/approval/:id",
      //   component: () => import("@/views/supervision/approval.vue"),
      // },
      {
        name: "pekerjaan-bidang-pengawasan",
        path: "/bidang-pengawasan/pekerjaan/:id",
        component: () => import("@/views/supervision/work/index.vue"),
      },
      {
        name: "edit-pekerjaan-bidang-pengawasan",
        path: "/bidang-pengawasan-v2/pekerjaan/:package_id/edit/:id",
        component: () => import("@/views/supervision/work/edit.vue"),
      },

      {
        name: "projects",
        path: "/projects",
        component: () => import("@/views/project/index.vue"),
      },
      {
        name: "projects-step",
        path: "/projects/step/:step",
        component: () => import("@/views/project/index.vue"),
      },
      {
        name: "projects-create",
        path: "/projects/create",
        component: () => import("@/views/project/Create.vue"),
      },
      {
        name: "projects-create-v2",
        path: "/projects/create-v2",
        component: () => import("@/views/project/CreateV2.vue"),
      },
      {
        name: "projects-create-pengawasan",
        path: "/projects/create-pengawasan",
        component: () => import("@/views/project/pengawasan/create.vue"),
      },
      {
        name: "projects-detail",
        path: "/projects/detail/:id",
        component: () => import("@/views/project/Detail.vue"),
      },
      {
        name: "projects-edit",
        path: "/projects/edit/:id",
        component: () => import("@/views/project/Edit.vue"),
      },

      {
        name: "user-management",
        path: "/user-management",
        component: () => import("@/views/user-management/index.vue"),
      },
      {
        name: "user-management-create",
        path: "/user-management/create",
        component: () => import("@/views/user-management/create.vue"),
      },
      {
        name: "user-management-edit",
        path: "/user-management/edit/:id",
        component: () => import("@/views/user-management/edit.vue"),
      },

      {
        name: "logs",
        path: "/logs",
        component: () => import("@/views/log/index.vue"),
      },

      {
        name: "monitoring",
        path: "/monitoring",
        component: () => import("@/views/monitoring/index.vue"),
      },
      {
        name: "pencarian",
        path: "/pencarian",
        component: () => import("@/views/pencarian/index.vue"),
      },
    ],
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const title = `DSDABM - ${to.name}`

  document.title = title.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
    letter.toUpperCase()
  )
  next()
})

export default router
